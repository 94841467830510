import { useQuery } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useMemo } from "react";
import { startOfWeek, isAfter, isBefore } from "date-fns";
import { Task, TaskFilters } from "@/types/task";
import { toast } from "sonner";
import { useTaskFilters } from "./useTaskFilters";

export const useTasks = (
  userEmail: string | null,
  teamMembers: string[],
  filters: TaskFilters
) => {
  const { data: tasks, isLoading } = useQuery({
    queryKey: ["tasks", userEmail, teamMembers],
    queryFn: async () => {
      if (!userEmail) return [];

      const query = supabase
        .from("to-do")
        .select("*");
        
      if (teamMembers.length > 0) {
        const allRelevantEmails = [...new Set([userEmail, ...teamMembers])];
        const ownerFilter = allRelevantEmails.map(email => `owner_email.eq.${email}`).join(',');

        query.or(`${ownerFilter},assignee_name.eq.${userEmail}`);
      } else {
        query.or(`owner_email.eq.${userEmail},assignee_name.eq.${userEmail}`);
      }
      
      query.order("deadline", { ascending: true, nullsFirst: false });

      const { data, error } = await query;

      if (error) {
        console.error("Error fetching tasks:", error);
        toast.error("Failed to load tasks");
        throw error;
      }
      
      return data as Task[];
    },
    enabled: !!userEmail,
  });

  const availableTags = useMemo(() => {
    if (!tasks) return [];
    const tagSet = new Set<string>();
    tasks.forEach((task) => {
      task.tags?.forEach((tag) => tagSet.add(tag));
    });
    return Array.from(tagSet);
  }, [tasks]);

  const filteredTasks = useTaskFilters(tasks, filters);

  const myTasks = filteredTasks.filter(task => !task.assignee_name || task.assignee_name === userEmail);
  const myIncompleteTasks = myTasks.filter((task) => !task.completed);
  const myCompletedTasks = myTasks.filter((task) => task.completed);
  const othersTasks = filteredTasks.filter(task => task.assignee_name && task.assignee_name !== userEmail && !task.completed);

  const weekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
  const today = new Date();
  
  const relevantTasks = myTasks.filter(task => {
    if (!task.deadline) return false;
    const deadlineDate = new Date(task.deadline);
    const isFutureOrThisWeek = isAfter(deadlineDate, weekStart) || 
                              deadlineDate.getTime() === weekStart.getTime();
    const isOverdueAndIncomplete = isBefore(deadlineDate, today) && !task.completed;
    return isFutureOrThisWeek || isOverdueAndIncomplete;
  });
  
  const relevantCompletedTasks = relevantTasks.filter(task => task.completed);
  const totalRelevantTasks = relevantTasks.length;
  const completedRelevantTasksCount = relevantCompletedTasks.length;

  return {
    tasks,
    filteredTasks,
    myTasks,
    myIncompleteTasks,
    myCompletedTasks,
    othersTasks,
    isLoading,
    availableTags,
    relevantTasks,
    totalRelevantTasks,
    completedRelevantTasksCount
  };
};
