import React, { createContext, useState, useEffect, useContext, ReactNode } from 'react';
import { supabase } from '@/integrations/supabase/client';
import type { Session, User } from '@supabase/supabase-js';

interface AuthContextType {
  session: Session | null;
  user: User | null;
  isEmailVerified: boolean;
  isLoading: boolean;
  isLoggedIn: boolean; // Convenience flag based on session
}

// Create the context with a default value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [session, setSession] = useState<Session | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [isEmailVerified, setIsEmailVerified] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Start as loading

  useEffect(() => {
    // Initial check for session
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      const currentUser = session?.user ?? null;
      setUser(currentUser);
      const verified = !!currentUser?.email_confirmed_at;
      setIsEmailVerified(verified);
      setIsLoading(false); // Finished initial load
      console.log("AuthProvider: Initial session fetched", session);
      console.log("AuthProvider: Initial verification status", verified);
    });

    // Set up listener for auth state changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        console.log("AuthProvider: Auth state changed", _event, session);
        setSession(session);
        const currentUser = session?.user ?? null;
        setUser(currentUser);
        const verified = !!currentUser?.email_confirmed_at;
        setIsEmailVerified(verified);
        // Don't set isLoading here, as this is an update, not initial load
      }
    );

    // Cleanup subscription on unmount
    return () => {
      subscription?.unsubscribe();
    };
  }, []);

  const value = {
    session,
    user,
    isEmailVerified,
    isLoading,
    isLoggedIn: !!session, // Derived state
  };

  // Provide the context value to children
  // Don't render children until initial loading is complete to prevent flashes
  return (
    <AuthContext.Provider value={value}>
      {!isLoading && children} 
    </AuthContext.Provider>
  );
};

// Custom hook to use the AuthContext
export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 