
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Card } from "@/components/ui/card";
import AuthForm from "@/components/auth/AuthForm";
import ConfirmationAlert from "@/components/auth/ConfirmationAlert";
import { handleHashParams, checkQueryParams } from "@/utils/auth-helpers";
import { supabase } from "@/integrations/supabase/client";

const Auth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLogin, setIsLogin] = useState(true);
  const [showConfirmationInfo, setShowConfirmationInfo] = useState(false);
  const [email, setEmail] = useState("");

  // Check for hash params from email confirmation links
  useEffect(() => {
    // Handle confirmation redirects with hash fragments
    const processConfirmation = async () => {
      const result = await handleHashParams(location, navigate);
      if (result) {
        // Success is already handled in handleHashParams
        return;
      }
    };
    
    processConfirmation();
    
    // Check for query params that indicate the user was invited or confirmed their email
    checkQueryParams(location);
  }, [location, navigate]);

  const handleFormSubmit = () => {
    navigate("/");
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4">
      <Card className="w-full max-w-md p-6 space-y-6">
        <h1 className="text-2xl font-heading font-bold text-center text-gray-900">
          taskvista {isLogin ? "Login" : "Registration"}
        </h1>
        
        {showConfirmationInfo && (
          <ConfirmationAlert email={email} />
        )}
        
        <AuthForm 
          isLogin={isLogin} 
          setIsLogin={setIsLogin}
          setShowConfirmationInfo={setShowConfirmationInfo}
          setEmail={setEmail}
          onSuccess={handleFormSubmit}
        />
      </Card>
    </div>
  );
};

export default Auth;
