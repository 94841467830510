
import { Users } from "lucide-react";

const EmptyCollaboratorsList = () => {
  return (
    <div className="text-center py-3 sm:py-4 text-gray-500">
      <Users className="mx-auto h-6 w-6 sm:h-8 sm:w-8 mb-1 sm:mb-2 text-gray-400" />
      <p className="text-sm">No collaborators yet</p>
      <p className="text-xs sm:text-sm">Invite people using the Invite button</p>
    </div>
  );
};

export default EmptyCollaboratorsList;
