import { MessageCircle } from "lucide-react";

interface CommentCountProps {
  count: number | undefined;
}

const CommentCount = ({ count }: CommentCountProps) => {
  if (count === undefined || count === 0) return null;

  return (
    <div className="flex items-center gap-1 text-gray-500">
      <MessageCircle className="h-4 w-4" />
      <span className="text-sm">{count}</span>
    </div>
  );
};

export default CommentCount;