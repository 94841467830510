import { toast } from "sonner";

interface MutationErrorHandlerOptions {
  message?: string;
  onError?: (error: Error) => void;
}

/**
 * Standard error handler for mutations
 */
export const handleMutationError = (error: Error, options: MutationErrorHandlerOptions = {}) => {
  const message = options.message || "An error occurred";
  toast.error(`${message}: ${error.message}`);
  console.error(message, error);
  
  if (options.onError) {
    options.onError(error);
  }
};

/**
 * Standard success handler for mutations
 */
export const handleMutationSuccess = (message: string, callback?: () => void) => {
  toast.success(message);
  
  if (callback) {
    callback();
  }
};
