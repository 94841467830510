import { DndContext, PointerSensor, useSensor, useSensors, MeasuringStrategy } from "@dnd-kit/core";
import { useMemo } from "react";
import TaskQuadrant from "./TaskQuadrant";
import { Task } from "@/types/task";
import { useTaskMutations } from "@/hooks/useTaskMutations";
import { Zap, Calendar, Users, Trash2, Icon as LucideIcon } from 'lucide-react';

// Define static quadrant configuration outside the component
const QUADRANT_CONFIG = [
  {
    importance: 1,
    urgency: 1,
    title: "Do first",
    description: "Important & Urgent",
    bgColor: "bg-red-50",
    droppableId: "1-1",
    Icon: Zap,
  },
  {
    importance: 1,
    urgency: 0,
    title: "Schedule",
    description: "Important & Not Urgent",
    bgColor: "bg-blue-50",
    droppableId: "1-0",
    Icon: Calendar,
  },
  {
    importance: 0,
    urgency: 1,
    title: "Delegate",
    description: "Less Important & Urgent",
    bgColor: "bg-yellow-50",
    droppableId: "0-1",
    Icon: Users,
  },
  {
    importance: 0,
    urgency: 0,
    title: "Eliminate",
    description: "Less Important & Not Urgent",
    bgColor: "bg-gray-50",
    droppableId: "0-0",
    Icon: Trash2,
  },
];

interface TaskQuadrantsSectionProps {
  myIncompleteTasks: Task[];
  isLoading: boolean;
}

const TaskQuadrantsSection = ({ myIncompleteTasks, isLoading }: TaskQuadrantsSectionProps) => {
  const { handleDragEnd } = useTaskMutations();
  
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
        delay: 200,
        tolerance: 5,
      },
    })
  );

  // Memoize the grouped tasks
  const tasksByQuadrant = useMemo(() => {
    const grouped: { [key: string]: Task[] } = {};
    // Initialize groups for all quadrants to prevent undefined errors later
    QUADRANT_CONFIG.forEach(q => {
      grouped[q.droppableId] = [];
    });
    // Group tasks
    (myIncompleteTasks || []).forEach(task => {
      const quadrantId = `${task.importance ?? 0}-${task.urgency ?? 0}`;
      if (grouped[quadrantId]) { // Check if quadrantId is valid
        grouped[quadrantId].push(task);
      } else {
        // This case should ideally not happen if tasks have valid importance/urgency
        // but good to handle defensively
        console.warn(`Task ${task.id} has invalid quadrant: ${quadrantId}`);
      }
    });
    return grouped;
  }, [myIncompleteTasks]);

  return (
    <DndContext 
      sensors={sensors} 
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
    >
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-4 mb-6 sm:mb-8">
        {/* Map over the static config */}
        {QUADRANT_CONFIG.map((config) => (
          <TaskQuadrant
            key={config.droppableId} // Use droppableId as key
            title={config.title}
            description={config.description}
            bgColor={config.bgColor}
            droppableId={config.droppableId}
            tasks={tasksByQuadrant[config.droppableId]} // Get tasks from memoized map
            isLoading={isLoading}
            IconComponent={config.Icon}
          />
        ))}
      </div>
    </DndContext>
  );
};

export default TaskQuadrantsSection;
