import { Button } from "@/components/ui/button";
import { Plus, LogOut, Filter, Users } from "lucide-react";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { useState, useEffect, lazy, Suspense } from "react";
import { useAuth } from "@/contexts/AuthContext";
import { useIsMobile } from "@/hooks/use-mobile";
import CollaboratorsDialog from "./CollaboratorsDialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

// Lazy load FilterDialog
const FilterDialog = lazy(() => import("./FilterDialog"));

interface TaskMatrixHeaderProps {
  onAddTask: () => void;
  onFilterChange: (filters: { search: string; tags: string[] }) => void;
  availableTags: string[];
}

const TaskMatrixHeader = ({ onAddTask, onFilterChange, availableTags }: TaskMatrixHeaderProps) => {
  const navigate = useNavigate();
  const { user, isEmailVerified } = useAuth();
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isCollaboratorsOpen, setIsCollaboratorsOpen] = useState(false);
  const isMobile = useIsMobile();
  const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

  useEffect(() => {
    const getAvatar = async () => {
      if (user?.id) { 
        const { data: profileData } = await supabase
          .from('profiles')
          .select('avatar_url')
          .eq('id', user.id)
          .single();
          
        if (profileData?.avatar_url) {
          setAvatarUrl(profileData.avatar_url);
        } else {
          setAvatarUrl(null);
        }
      } else {
        setAvatarUrl(null);
      }
    };
    
    getAvatar();
  }, [user?.id]);

  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error("Error logging out");
    } else {
      toast.success("Logged out successfully");
      navigate("/auth");
    }
  };

  const handleAddTaskClick = () => {
    if (!isEmailVerified) {
      toast.error("Please verify your email before adding tasks");
      return;
    }
    onAddTask();
  };

  const getUserInitials = () => {
    const email = user?.email;
    if (!email) return "?";
    return email.substring(0, 2).toUpperCase();
  };

  return (
    <>
      <div className="bg-white dark:bg-gray-950 border-b border-gray-200 dark:border-gray-800 px-4 sm:px-6 py-3 sm:py-4 mb-4 sm:mb-6 shadow-sm">
        <div className="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
          <h1 className="text-xl sm:text-2xl md:text-3xl font-heading font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
            taskvista
            {!isEmailVerified && (
              <span className="ml-2 text-xs text-amber-600 font-normal align-middle bg-amber-100 py-1 px-2 rounded">
                Email verification pending
              </span>
            )}
          </h1>
          
          <div className="flex items-center flex-wrap gap-2 w-full sm:w-auto justify-start sm:justify-end">
            <Tooltip>
              <TooltipTrigger asChild>
                <div>
                  <Button 
                    size={isMobile ? "sm" : "default"}
                    className={`${isEmailVerified ? 'bg-indigo-600 hover:bg-indigo-700' : 'bg-indigo-400'} text-white shadow-sm`}
                    onClick={handleAddTaskClick}
                    disabled={!isEmailVerified}
                  >
                    <Plus className="mr-1 h-3.5 w-3.5 sm:h-4 sm:w-4" />
                    <span className="text-xs sm:text-sm">Add</span>
                  </Button>
                </div>
              </TooltipTrigger>
              {!isEmailVerified && (
                <TooltipContent>
                  <p>Verify your email to add tasks</p>
                </TooltipContent>
              )}
            </Tooltip>
            
            <Button 
              size={isMobile ? "sm" : "default"}
              variant="outline" 
              className="border-gray-200 shadow-sm"
              onClick={() => setIsFilterOpen(true)}
            >
              <Filter className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
              <span className="sr-only sm:not-sr-only sm:ml-1.5 sm:text-sm">Filter</span>
            </Button>
            
            <Button 
              size={isMobile ? "sm" : "default"}
              variant="outline"
              className="border-gray-200 shadow-sm"
              onClick={() => setIsCollaboratorsOpen(true)}
            >
              <Users className="h-3.5 w-3.5 sm:h-4 sm:w-4" />
              <span className="sr-only sm:not-sr-only sm:ml-1.5 sm:text-sm">Team</span>
            </Button>
            
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Avatar className="h-8 w-8 cursor-pointer hover:opacity-80 transition-opacity">
                  <AvatarImage src={avatarUrl || ""} alt="Profile" />
                  <AvatarFallback className="bg-indigo-100 text-indigo-800">
                    {getUserInitials()}
                  </AvatarFallback>
                </Avatar>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuLabel>
                  <div className="flex flex-col">
                    <span className="font-medium">My Account</span>
                    <span className="text-xs text-gray-500 truncate max-w-[200px]">{user?.email}</span>
                  </div>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={handleLogout} className="text-red-500 cursor-pointer">
                  <LogOut className="h-4 w-4 mr-2" />
                  Logout
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>
      
      <Suspense fallback={<div>Loading...</div>}>
        {isFilterOpen && (
          <FilterDialog
            open={isFilterOpen}
            onOpenChange={setIsFilterOpen}
            onFilterChange={onFilterChange}
            availableTags={availableTags}
          />
        )}
      </Suspense>
      
      <CollaboratorsDialog 
        open={isCollaboratorsOpen}
        onOpenChange={setIsCollaboratorsOpen}
      />
    </>
  );
};

export default TaskMatrixHeader;
