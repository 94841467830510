import { 
  Dialog, 
  DialogContent, 
  DialogDescription, 
  DialogHeader, 
  DialogTitle 
} from "@/components/ui/dialog";
import { useEffect, useState, lazy, Suspense } from "react";
import CollaboratorsList from "./collaborators/CollaboratorsList";
import { useCollaborators } from "@/hooks/useCollaborators";
import { Button } from "./ui/button";
import { Plus, Users } from "lucide-react";

// Lazy load nested dialogs
const InviteDialog = lazy(() => import("./InviteDialog"));
const AddTeamMemberDialog = lazy(() => import("./AddTeamMemberDialog"));

interface CollaboratorsDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const CollaboratorsDialog = ({ open, onOpenChange }: CollaboratorsDialogProps) => {
  const { fetchCollaborators } = useCollaborators(false);
  const [showInviteDialog, setShowInviteDialog] = useState(false);
  const [showAddMemberDialog, setShowAddMemberDialog] = useState(false);

  // Fetch collaborators when the dialog opens
  useEffect(() => {
    if (open) {
      fetchCollaborators();
    }
  }, [open, fetchCollaborators]);

  return (
    <>
      <Dialog open={open} onOpenChange={onOpenChange}>
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Team Members</DialogTitle>
            <DialogDescription>
              People who have access to your task list
            </DialogDescription>
          </DialogHeader>
          
          <div className="flex gap-2 mb-2">
            <Button 
              onClick={() => setShowInviteDialog(true)} 
              variant="outline" 
              className="text-sm"
              size="sm"
            >
              <Plus className="h-4 w-4 mr-1" /> Invite via Email
            </Button>
            
            <Button 
              onClick={() => setShowAddMemberDialog(true)} 
              variant="outline" 
              className="text-sm"
              size="sm"
            >
              <Users className="h-4 w-4 mr-1" /> Add Directly
            </Button>
          </div>
          
          <div className="pt-2">
            <CollaboratorsList />
          </div>
        </DialogContent>
      </Dialog>
      
      <Suspense fallback={<div>Loading...</div>}>
        {showInviteDialog && (
          <InviteDialog 
            open={showInviteDialog} 
            onOpenChange={setShowInviteDialog} 
          />
        )}
        {showAddMemberDialog && (
          <AddTeamMemberDialog 
            open={showAddMemberDialog} 
            onOpenChange={setShowAddMemberDialog} 
          />
        )}
      </Suspense>
    </>
  );
};

export default CollaboratorsDialog;
