
import { Badge } from "@/components/ui/badge";
import { Users } from "lucide-react";
import { useCollaborators } from "@/hooks/useCollaborators";
import CollaboratorItem from "./CollaboratorItem";
import DiagnosticInfo from "./DiagnosticInfo";
import EmptyCollaboratorsList from "./EmptyCollaboratorsList";
import { Skeleton } from "@/components/ui/skeleton";
import { useEffect } from "react";

const CollaboratorsList = () => {
  const { collaborators, loading, diagnosticInfo, fetchCollaborators, removeCollaborator } = useCollaborators(false);

  // Refresh the list when the component mounts
  useEffect(() => {
    fetchCollaborators();
  }, [fetchCollaborators]);

  if (loading) {
    return (
      <div className="p-3 sm:p-4 rounded-lg border border-gray-200 bg-white">
        <div className="flex items-center justify-between mb-3 sm:mb-4">
          <h3 className="text-base sm:text-lg font-medium">Collaborators</h3>
        </div>
        <div className="space-y-2">
          <Skeleton className="h-8 sm:h-10 bg-gray-100 rounded" />
          <Skeleton className="h-8 sm:h-10 bg-gray-100 rounded" />
        </div>
      </div>
    );
  }

  return (
    <div className="p-3 sm:p-4 rounded-lg border border-gray-200 bg-white">
      <div className="flex items-center justify-between mb-3 sm:mb-4">
        <h3 className="text-base sm:text-lg font-medium">Collaborators</h3>
        <Badge variant="outline" className="flex gap-1 items-center">
          <Users className="h-3 w-3" />
          {collaborators.length}
        </Badge>
      </div>
      
      {collaborators.length === 0 ? (
        <EmptyCollaboratorsList />
      ) : (
        <>
          <ul className="space-y-2">
            {collaborators.map((collaborator) => (
              <CollaboratorItem 
                key={collaborator.id} 
                collaborator={collaborator} 
                onRemove={removeCollaborator}
              />
            ))}
          </ul>
          
          <DiagnosticInfo diagnosticInfo={diagnosticInfo} />
        </>
      )}
    </div>
  );
};

export default CollaboratorsList;
