import { Card } from "@/components/ui/card";
import TaskList from "./TaskList";
import { Task } from "@/types/task";
import { useDroppable } from "@dnd-kit/core";
import { cn } from "@/lib/utils";
import { useState } from "react";
import { useTaskMutations } from "@/hooks/useTaskMutations";
import React from 'react';
import { Icon as LucideIcon } from 'lucide-react';
import { createLazyComponent } from "../utils/lazyLoad";
import { getQuadrantStyles } from "../utils/quadrantStyles";

// Lazy load dialogs
const EditTaskDialog = createLazyComponent(() => import("./EditTaskDialog"));
const DeleteTaskDialog = createLazyComponent(() => import("./DeleteTaskDialog"));

interface TaskQuadrantProps {
  title: string;
  description: string;
  tasks: Task[];
  bgColor: string;
  isLoading: boolean;
  droppableId: string;
  IconComponent: React.ComponentType<{ className?: string }>;
}

const TaskQuadrant = ({ title, description, tasks, bgColor, isLoading, droppableId, IconComponent }: TaskQuadrantProps) => {
  const { setNodeRef } = useDroppable({
    id: droppableId,
  });

  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { deleteTask } = useTaskMutations();

  const handleEditTask = (task: Task) => {
    setSelectedTask(task);
    setIsEditOpen(true);
  };

  const handleDeleteTask = (task: Task) => {
    setSelectedTask(task);
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedTask) {
      deleteTask.mutate(selectedTask.id);
    }
    setIsDeleteOpen(false);
    setSelectedTask(null);
  };


  return (
    <>
      <Card 
        className={cn(
          "flex flex-col h-full p-3 sm:p-5 border shadow-sm transition-all duration-200",
          getQuadrantStyles(bgColor)
        )} 
        ref={setNodeRef}
      >
        <div className="border-b pb-2 sm:pb-3 mb-3 sm:mb-4 flex-shrink-0 flex items-center gap-2">
          <IconComponent className="h-5 w-5 text-gray-400 flex-shrink-0" />
          <div>
            <h2 className="text-lg sm:text-xl md:text-2xl font-bold mb-0">{title}</h2>
            <p className="text-2xs sm:text-xs text-gray-500">{description}</p>
          </div>
        </div>
        <div className="flex-grow overflow-y-auto">
          <TaskList 
            tasks={tasks} 
            isLoading={isLoading} 
            draggable={true} 
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
          />
        </div>
      </Card>

      {isEditOpen && selectedTask && (
        <EditTaskDialog
          task={selectedTask}
          open={isEditOpen}
          onOpenChange={(isOpen) => {
            setIsEditOpen(isOpen);
            if (!isOpen) setSelectedTask(null);
          }}
        />
      )}
      {isDeleteOpen && (
        <DeleteTaskDialog
          open={isDeleteOpen}
          onOpenChange={(isOpen) => {
            setIsDeleteOpen(isOpen);
            if (!isOpen) setSelectedTask(null);
          }}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

export default TaskQuadrant;
