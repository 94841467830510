import { Task } from "@/types/task";
import TaskDetails from "./TaskDetails";
import TaskActions from "./TaskActions";
import CommentCount from "./CommentCount";
import { useIsMobile } from "@/hooks/use-mobile";
import { Checkbox } from "@/components/ui/checkbox";
import { supabase } from "@/integrations/supabase/client";
import { useDraggable } from "@dnd-kit/core";
import { TaskActionProps } from "../types/componentProps";

interface TaskItemProps extends TaskActionProps {
  commentCount: number | undefined;
  draggable?: boolean;
}

const TaskItem = ({
  task,
  commentCount,
  onEditClick,
  onDeleteClick,
  onCalendarClick,
  onCompleteClick,
  draggable = false,
}: TaskItemProps) => {
  const isMobile = useIsMobile();

  const { attributes, listeners, setNodeRef, transform } = draggable 
    ? useDraggable({
        id: task.id,
        data: {
          importance: task.importance,
          urgency: task.urgency
        }
      })
    : { attributes: {}, listeners: {}, setNodeRef: () => {}, transform: null };

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
    transition: 'transform 0.01s',
    willChange: 'transform',
  } : undefined;

  const isOverdue = task.deadline && new Date(task.deadline) < new Date() && !task.completed;

  return (
    <div
      ref={draggable ? setNodeRef : undefined}
      style={style}
      {...(draggable ? attributes : {})}
      {...(draggable ? listeners : {})}
      className={`flex flex-col sm:flex-row items-start sm:items-center justify-between p-3 sm:p-4 bg-white rounded-lg border border-gray-100 shadow-sm hover:shadow transition-all gap-2 sm:gap-3 ${
        draggable ? 'cursor-grab active:cursor-grabbing' : 'cursor-default'
      } ${
        isOverdue ? "border-l-4 border-red-500" : ""
      } transform-gpu`}
    >
      <div 
        className="flex items-start gap-2 sm:gap-3 flex-1 min-w-0 w-full"
        onClick={(e) => {
          const isDragging = !!transform;
          if (!isDragging) {
            onEditClick(task);
          } 
        }}
      >
        <TaskDetails 
          task={task} 
          onTaskClick={() => onEditClick(task)}
          isOverdue={isOverdue}
          checkbox={
            <Checkbox
              checked={task.completed}
              onCheckedChange={(checked) => {
                if (checked && onCompleteClick) {
                  onCompleteClick(task);
                } else if (!checked) {
                  if (checked === true && onCompleteClick) {
                    onCompleteClick(task);
                  }
                }
              }}
              disabled={task.completed || !onCompleteClick}
            />
          }
        />
      </div>
      <div className="flex items-center gap-2 sm:gap-3 w-full sm:w-auto justify-end mt-2 sm:mt-0">
        <CommentCount count={commentCount} />
        <TaskActions
          task={task}
          onDeleteClick={onDeleteClick}
          onCalendarClick={onCalendarClick}
          showLabels={false}
        />
      </div>
    </div>
  );
};

export default TaskItem;
