import { Card } from "@/components/ui/card";
import TaskList from "./TaskList";
import { Task } from "@/types/task";

interface OthersTasksProps {
  tasks: Task[];
}

const OthersTasks = ({ tasks }: OthersTasksProps) => {
  if (!tasks || tasks.length === 0) return null;

  return (
    <Card className="p-3 sm:p-4 mb-6 sm:mb-8 bg-[#FFF2E9]">
      <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">Notable tasks of others</h2>
      <TaskList 
        tasks={tasks} 
        draggable={false} 
      />
    </Card>
  );
};

export default OthersTasks;
