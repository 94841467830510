
interface DiagnosticInfoProps {
  diagnosticInfo: any;
}

const DiagnosticInfo = ({ diagnosticInfo }: DiagnosticInfoProps) => {
  if (!diagnosticInfo || process.env.NODE_ENV === 'production') {
    return null;
  }

  return (
    <div className="mt-4 border-t pt-4 text-xs">
      <details>
        <summary className="cursor-pointer font-semibold text-gray-500">Debug Information</summary>
        <div className="mt-2 p-2 bg-gray-50 rounded overflow-auto max-h-40">
          <p>Current User: {diagnosticInfo.currentUserEmail}</p>
          <p>Active Profiles: {diagnosticInfo.activeProfiles?.length || 0}</p>
          <p>Pending Invitations: {diagnosticInfo.pendingInvitations?.length || 0}</p>
          <div className="mt-1">
            <p className="font-semibold">Pending Invitations Data:</p>
            <pre>{JSON.stringify(diagnosticInfo.pendingInvitations, null, 2)}</pre>
          </div>
        </div>
      </details>
    </div>
  );
};

export default DiagnosticInfo;
