import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { DragEndEvent } from "@dnd-kit/core";
import { Task } from "@/types/task";
import { addDays, addWeeks, addMonths } from "date-fns";
import { TaskFormValues } from "../validation/taskSchema";
import { handleMutationError, handleMutationSuccess } from "../utils/mutationUtils";

const calculateNextDeadline = (task: Task): string | null => {
  if (!task.deadline || !task.is_recurring || !task.recurrence_frequency) return null;
  
  const currentDeadline = new Date(task.deadline);
  let nextDeadline;
  
  switch (task.recurrence_frequency) {
    case 'daily':
      nextDeadline = addDays(currentDeadline, 1);
      break;
    case 'weekly':
      nextDeadline = addWeeks(currentDeadline, 1);
      break;
    case 'monthly':
      nextDeadline = addMonths(currentDeadline, 1);
      break;
    case 'quarterly':
      nextDeadline = addMonths(currentDeadline, 3);
      break;
    default:
      return null;
  }
  
  return nextDeadline.toISOString();
};

export const useTaskMutations = () => {
  const queryClient = useQueryClient();

  const addTask = useMutation({
    mutationFn: async (values: TaskFormValues) => {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user?.email) throw new Error("User not authenticated or email missing");

      const { error } = await supabase.from("to-do").insert([
        {
          task: values.task,
          importance: parseInt(values.importance),
          urgency: parseInt(values.urgency),
          deadline: values.deadline || null,
          tags: values.tags,
          owner_email: user.email,
          is_recurring: values.is_recurring || false,
          recurrence_frequency: values.is_recurring ? values.recurrence_frequency : null,
          completed: false,
          notification_sent: false
        },
      ]);
      if (error) {
        console.error("Supabase insert error:", error);
        throw error;
      } 
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      handleMutationSuccess("Task added successfully");
    },
    onError: (error) => {
      handleMutationError(error, { 
        message: "Failed to add task"
      });
    },
  });

  const updateTask = useMutation({
    mutationFn: async ({ taskId, values }: { taskId: number; values: TaskFormValues }) => {
      const updateData = {
        task: values.task,
        importance: parseInt(values.importance), 
        urgency: parseInt(values.urgency),
        deadline: values.deadline || null,
        tags: values.tags,
        is_recurring: values.is_recurring || false,
        recurrence_frequency: values.is_recurring ? values.recurrence_frequency : null,
      };

      const { error } = await supabase
        .from("to-do")
        .update(updateData)
        .eq("id", taskId);

      if (error) {
        console.error("Supabase update error:", error);
        throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      handleMutationSuccess("Task updated successfully");
    },
    onError: (error) => {
      handleMutationError(error, { 
        message: "Failed to update task"
      });
    },
  });

  const updateTaskPriority = useMutation({
    mutationFn: async ({ taskId, importance, urgency }: { taskId: number; importance: number; urgency: number }) => {
      const { error } = await supabase
        .from("to-do")
        .update({ importance, urgency })
        .eq("id", taskId);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      handleMutationSuccess("Task priority updated");
    },
    onError: (error) => {
      handleMutationError(error, { 
        message: "Failed to update task priority"
      });
    },
  });

  const completeTask = useMutation({
    mutationFn: async (task: Task) => {
      const now = new Date().toISOString();
      
      if (task.is_recurring) {
        const nextDeadline = calculateNextDeadline(task);
        
        if (nextDeadline) {
          await supabase
            .from("to-do")
            .update({ completed: true, last_completed_at: now })
            .eq("id", task.id);
          
          const { error } = await supabase.from("to-do").insert([{
            task: task.task,
            importance: task.importance,
            urgency: task.urgency,
            deadline: nextDeadline,
            tags: task.tags,
            assignee_name: task.assignee_name,
            is_recurring: task.is_recurring,
            recurrence_frequency: task.recurrence_frequency,
            owner_email: task.owner_email,
            completed: false,
            notification_sent: false
          }]);
          if (error) throw error;
        } else {
          const { error } = await supabase
            .from("to-do")
            .update({ completed: true, last_completed_at: now })
            .eq("id", task.id);
          if (error) throw error;
        }
      } else {
        const { error } = await supabase
          .from("to-do")
          .update({ completed: true, last_completed_at: now })
          .eq("id", task.id);
        if (error) throw error;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      handleMutationSuccess("Task marked as complete");
    },
    onError: (error) => {
      handleMutationError(error, { 
        message: "Failed to update task"
      });
    },
  });

  const deleteTask = useMutation({
    mutationFn: async (taskId: number) => {
      const { error } = await supabase.from("to-do").delete().eq("id", taskId);
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      handleMutationSuccess("Task deleted successfully");
    },
    onError: (error) => {
      handleMutationError(error, { 
        message: "Failed to delete task"
      });
    },
  });

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    
    if (!over) return;

    const taskId = Number(active.id);
    const [importance, urgency] = over.id.toString().split("-").map(Number);
    
    const activeData = active.data?.current as any;
    if (activeData?.importance === importance && activeData?.urgency === urgency) {
      return;
    }
    
    updateTaskPriority.mutate({ taskId, importance, urgency });
  };

  return {
    addTask,
    updateTask,
    updateTaskPriority,
    completeTask,
    deleteTask,
    handleDragEnd
  };
};
