
import { Button } from "@/components/ui/button";
import { Calendar, X } from "lucide-react";
import { Task } from "@/types/task";

interface TaskActionsProps {
  task: Task;
  onDeleteClick: (task: Task) => void;
  onCalendarClick: (task: Task) => void;
  showLabels?: boolean;
}

const TaskActions = ({
  task,
  onDeleteClick,
  onCalendarClick,
}: TaskActionsProps) => (
  <div className="flex gap-1">
    <Button
      variant="ghost"
      size="sm"
      onClick={(e) => {
        e.stopPropagation();
        onCalendarClick(task);
      }}
      title="Add to Google Calendar"
      className="h-8"
    >
      <Calendar className="h-4 w-4" />
    </Button>
    <Button
      variant="ghost"
      size="sm"
      onClick={(e) => {
        e.stopPropagation();
        onDeleteClick(task);
      }}
      className="h-8"
    >
      <X className="h-4 w-4" />
    </Button>
  </div>
);

export default TaskActions;
