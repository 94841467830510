import { useState, useEffect } from "react";
import { useTasks } from "@/hooks/useTasks";
import { useCollaborators } from "@/hooks/useCollaborators";
import { TaskFilters } from "@/types/task";
import { supabase } from "@/integrations/supabase/client";
import { Collaborator } from "@/utils/collaborators/types";
import TaskMatrixHeader from "./TaskMatrixHeader";
import TaskQuadrantsSection from "./TaskQuadrantsSection";
import OthersTasks from "./OthersTasks";
import TasksByDeadline from "./TasksByDeadline";
import TaskProgress from "./TaskProgress";
import CompletedTasks from "./CompletedTasks";
import { createLazyComponent } from "../utils/lazyLoad";

// Lazy load the dialog
const AddTaskDialog = createLazyComponent(() => import("./AddTaskDialog"));

const TaskMatrix = () => {
  const [isAddTaskOpen, setIsAddTaskOpen] = useState(false);
  const [filters, setFilters] = useState<TaskFilters>({ search: "", tags: [] });
  const [userEmail, setUserEmail] = useState<string | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      setUserEmail(user?.email ?? null);
    };
    fetchUser();
  }, []);

  const { 
    collaborators, 
    loading: collaboratorsLoading 
  } = useCollaborators();

  const teamMembers = userEmail 
    ? (collaborators || [])
        .filter((c: Collaborator) => c.email !== userEmail && c.status === 'active') 
        .map((c: Collaborator) => c.email)
    : [];
  
  const {
    myIncompleteTasks,
    myCompletedTasks,
    othersTasks,
    isLoading: tasksLoading,
    availableTags,
    totalRelevantTasks,
    completedRelevantTasksCount
  } = useTasks(userEmail, teamMembers, filters);

  const isLoading = collaboratorsLoading || tasksLoading || userEmail === null;

  return (
    <div className="container mx-auto px-2 sm:px-4 py-2 sm:py-4">
      <TaskMatrixHeader
        onAddTask={() => setIsAddTaskOpen(true)}
        onFilterChange={setFilters}
        availableTags={availableTags}
      />

      <TaskProgress 
        totalTasks={totalRelevantTasks} 
        completedTasks={completedRelevantTasksCount} 
      />

      <TaskQuadrantsSection
        myIncompleteTasks={myIncompleteTasks}
        isLoading={isLoading}
      />

      <OthersTasks tasks={othersTasks} />
      <TasksByDeadline tasks={myIncompleteTasks} isLoading={isLoading} />
      <CompletedTasks tasks={myCompletedTasks} />

      {/* Lazy loaded dialog */}
      {isAddTaskOpen && <AddTaskDialog open={isAddTaskOpen} onOpenChange={setIsAddTaskOpen} />}
    </div>
  );
};

export default TaskMatrix;
