
import { useState } from "react";
import { User, Clock, Trash2, UserCheck } from "lucide-react";
import { 
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { Collaborator } from "@/hooks/useCollaborators";
import { toast } from "sonner";

interface CollaboratorItemProps {
  collaborator: Collaborator;
  onRemove: (collaborator: Collaborator) => Promise<void>;
}

const CollaboratorItem = ({ collaborator, onRemove }: CollaboratorItemProps) => {
  const [isRemoving, setIsRemoving] = useState(false);
  
  // Get formatted time (e.g., "2 days ago")
  const getTimeAgo = (dateString?: string) => {
    if (!dateString) return "";
    
    const date = new Date(dateString);
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    
    if (diffInDays === 0) {
      const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));
      if (diffInHours === 0) {
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        return `${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
      }
      return `${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
    }
    
    if (diffInDays === 1) return "Yesterday";
    if (diffInDays < 30) return `${diffInDays} days ago`;
    
    const months = Math.floor(diffInDays / 30);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  };
  
  const handleRemove = async () => {
    try {
      setIsRemoving(true);
      await onRemove(collaborator);
      toast.success(`${collaborator.status === 'pending' ? 'Invitation' : 'Team member'} removed successfully`);
    } catch (error) {
      console.error("Error removing collaborator:", error);
      toast.error("Failed to remove team member");
    } finally {
      setIsRemoving(false);
    }
  };
  
  return (
    <div className="flex items-center justify-between p-3 border-b border-gray-100 last:border-0">
      <div className="flex items-center space-x-3">
        <Avatar className="h-9 w-9">
          <AvatarImage src={collaborator.avatar_url || ""} />
          <AvatarFallback className="bg-blue-100 text-blue-800">
            <User className="h-4 w-4" />
          </AvatarFallback>
        </Avatar>
        
        <div>
          <div className="font-medium text-sm flex items-center gap-2">
            {collaborator.name || collaborator.email}
            {collaborator.status === 'pending' && (
              <span className="bg-amber-100 text-amber-700 text-xs px-2 py-0.5 rounded">
                Pending
              </span>
            )}
            {collaborator.status === 'direct' && (
              <span className="bg-green-100 text-green-700 text-xs px-2 py-0.5 rounded">
                Direct
              </span>
            )}
          </div>
          <div className="text-xs text-gray-500 flex items-center">
            {collaborator.status === 'pending' ? (
              <>
                <Clock className="h-3 w-3 mr-1" />
                Invited {getTimeAgo(collaborator.invited_at)}
              </>
            ) : collaborator.status === 'direct' ? (
              <>
                <UserCheck className="h-3 w-3 mr-1" />
                Added {getTimeAgo(collaborator.invited_at)}
              </>
            ) : (
              <>
                <UserCheck className="h-3 w-3 mr-1" />
                Joined {getTimeAgo(collaborator.invited_at)}
              </>
            )}
          </div>
        </div>
      </div>
      
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button 
            variant="ghost" 
            size="icon" 
            className="h-8 w-8 text-gray-400 hover:text-red-500" 
            disabled={isRemoving}
          >
            <Trash2 className="h-4 w-4" />
          </Button>
        </AlertDialogTrigger>
        
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Remove team member?</AlertDialogTitle>
            <AlertDialogDescription>
              {collaborator.status === 'pending' 
                ? `This will cancel the invitation sent to ${collaborator.email}.`
                : `This will remove ${collaborator.name || collaborator.email} from your team and they will lose access to your tasks.`
              }
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction 
              onClick={handleRemove}
              className="bg-red-500 hover:bg-red-600 text-white"
            >
              {isRemoving ? "Removing..." : "Remove"}
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
};

export default CollaboratorItem;
