import { useMemo } from "react";
import { Task, TaskFilters } from "../types/task";

/**
 * Custom hook to filter tasks based on search and tags
 */
export const useTaskFilters = (tasks: Task[] | null, filters: TaskFilters) => {
  return useMemo(() => {
    if (!tasks) return [];
    
    if (!filters.search && !filters.tags.length) {
      return tasks;
    }
    
    return tasks.filter((task) => {
      const searchLower = filters.search.toLowerCase();
      const matchesSearch = !filters.search || 
        task.task.toLowerCase().includes(searchLower) || 
        (task.assignee_name && task.assignee_name.toLowerCase().includes(searchLower)) ||
        (task.owner_email && task.owner_email.toLowerCase().includes(searchLower));

      const matchesTags = !filters.tags.length || 
        filters.tags.every((tag) => task.tags?.includes(tag));
      
      return matchesSearch && matchesTags;
    });
  }, [tasks, filters]);
};
