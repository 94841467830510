import { useState, useEffect, useCallback } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Collaborator } from "../utils/collaborators/types";
import type { User } from "@supabase/supabase-js";

// Define types for Supabase data
interface ProfileData {
  id: string;
  email?: string | null;
  full_name?: string | null;
  avatar_url?: string | null;
  invited_at?: string | null;
  invited_by?: string | null;
}

interface InvitationData {
  id: number; // Assuming id is a number in pending_invitations
  email: string;
  invited_by: string;
  invited_at: string;
  direct_add?: boolean | null; // Based on usage in the map
}

// Updated CollaboratorsData interface
export interface CollaboratorsData {
  collaborators: Collaborator[];
  loading: boolean;
  fetchCollaborators: () => Promise<void>;
  removeCollaborator: (collaborator: Collaborator) => Promise<void>;
}

export function useCollaborators(autoRefresh = false): CollaboratorsData {
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [loading, setLoading] = useState(true);
  const { toast } = useToast();

  const fetchCollaborators = useCallback(async () => {
    try {
      setLoading(true);
      
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error("Not authenticated");
      }

      // Fetch profiles and invitations
      const { data: activeProfiles, error: profilesError } = await supabase
        .from('profiles')
        .select('id, email, full_name, avatar_url, invited_at, invited_by')
        .neq('id', user.id);
      
      if (profilesError) throw profilesError;

      const { data: pendingInvitations, error: invitationsError } = await supabase
        .from('pending_invitations')
        .select('id, email, invited_by, invited_at, direct_add');
      
      if (invitationsError) throw invitationsError;

      // Process active profiles
      const activeCollaborators = (activeProfiles || []).map((profile: ProfileData): Collaborator => ({
        id: profile.id,
        email: profile.email || `user-${profile.id}`,
        full_name: profile.full_name || undefined,
        avatar_url: profile.avatar_url || undefined,
        status: profile.email ? 'active' : 'direct',
        invited_at: profile.invited_at || undefined,
        invited_by: profile.invited_by || undefined
      }));

      // Process pending invitations
      const pendingCollaborators = (pendingInvitations || []).map((invitation: InvitationData): Collaborator => ({
        id: `pending-${invitation.id}`,
        email: invitation.email,
        status: invitation.direct_add ? 'direct' : 'pending',
        invited_at: invitation.invited_at,
        invited_by: invitation.invited_by,
        full_name: undefined,
        avatar_url: undefined,
      }));
      
      // Combine and sort
      const allCollaborators: Collaborator[] = [...activeCollaborators, ...pendingCollaborators]
        .sort((a, b) => {
          if (!a.invited_at) return 1;
          if (!b.invited_at) return -1;
          return new Date(b.invited_at).getTime() - new Date(a.invited_at).getTime();
        });
      
      // Update collaborators state
      setCollaborators(allCollaborators);

    } catch (error) {
      console.error("Error fetching collaborators:", error);
      toast({
        title: "Error",
        description: "Failed to load collaborators",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  }, [toast]);

  const removeCollaborator = async (collaborator: Collaborator) => {
    try {
      // Check if this is a pending invitation or active/direct user
      if (collaborator.status === 'pending' || collaborator.status === 'direct') {
        // Extract the numeric ID from the pending-{id} format
        const invitationId = parseInt(collaborator.id.replace('pending-', ''), 10);
        
        // Delete from pending_invitations table
        const { error } = await supabase
          .from('pending_invitations')
          .delete()
          .eq('id', invitationId);
        
        if (error) throw error;
      } else {
        // For active users, remove from profiles
        const { error } = await supabase
          .from('profiles')
          .delete()
          .eq('id', collaborator.id);
        
        if (error) throw error;
      }
      
      // Update local state
      setCollaborators(current => 
        current.filter(c => c.id !== collaborator.id)
      );
      
      // Show success toast after successful deletion and state update
      toast({ 
        title: "Success",
        description: "Team member removed.",
        variant: "default", // Or your preferred variant
      });

    } catch (error) {
      console.error("Error removing collaborator:", error);
      // Show error toast
      toast({ 
        title: "Error",
        description: "Failed to remove team member.",
        variant: "destructive",
      });
      // Re-throw error if needed by the caller, otherwise handle here
      // throw error; 
    }
  };

  // Fetch collaborators on mount
  useEffect(() => {
    fetchCollaborators();
    
    // Only set up interval if autoRefresh is enabled
    if (autoRefresh) {
      let lastUpdate = Date.now();
      const interval = setInterval(() => {
        // Add throttling to prevent excessive updates
        if (Date.now() - lastUpdate >= 5000) {
          lastUpdate = Date.now();
          fetchCollaborators();
        }
      }, 5000);
      
      return () => clearInterval(interval);
    }
  }, [autoRefresh, fetchCollaborators]);

  return {
    collaborators,
    loading,
    fetchCollaborators,
    removeCollaborator
  };
}
