
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Info } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";
import { getProductionUrl } from "@/utils/auth-helpers";

interface ConfirmationAlertProps {
  email: string;
}

const ConfirmationAlert = ({ email }: ConfirmationAlertProps) => {
  const [loading, setLoading] = useState(false);

  const resendConfirmationEmail = async () => {
    if (!email) {
      toast.error("Please enter your email address");
      return;
    }
    
    try {
      setLoading(true);
      console.log("Resending confirmation email to:", email);
      
      // CRITICAL FIX: Use the production URL for redirect
      const redirectUrl = getProductionUrl() + "/auth?confirmed=true";
      
      const { error } = await supabase.auth.resend({
        type: "signup",
        email,
        options: {
          emailRedirectTo: redirectUrl,
        }
      });
      
      console.log("Resend response:", { error });
      
      if (error) {
        toast.error("Failed to resend: " + error.message);
      } else {
        toast.success("Confirmation email resent. Please check your inbox.");
      }
    } catch (error) {
      console.error("Error resending confirmation:", error);
      toast.error("Failed to resend confirmation email");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Alert className="bg-blue-50 border-blue-200">
      <Info className="h-4 w-4 text-blue-600" />
      <AlertDescription className="text-sm text-blue-800">
        <p>Please check your email and click the confirmation link to get full access to all features.</p>
        <Button 
          variant="link" 
          className="p-0 h-auto text-blue-600 underline" 
          onClick={resendConfirmationEmail}
          disabled={loading}
        >
          {loading ? "Sending..." : "Resend confirmation email"}
        </Button>
      </AlertDescription>
    </Alert>
  );
};

export default ConfirmationAlert;
