import { useState, lazy, Suspense } from "react";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Trash2, ChevronDown, ChevronUp, Calendar } from "lucide-react";
import TaskList from "./TaskList";
import { Task } from "@/types/task";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { toast } from "sonner";
import { Badge } from "@/components/ui/badge";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { format } from "date-fns";
import { useTaskMutations } from "@/hooks/useTaskMutations";

// Lazy load dialogs
const EditTaskDialog = lazy(() => import("./EditTaskDialog"));
const DeleteTaskDialog = lazy(() => import("./DeleteTaskDialog"));

interface CompletedTasksProps {
  tasks: Task[];
}

const CompletedTasks = ({ tasks }: CompletedTasksProps) => {
  const [expanded, setExpanded] = useState(false);
  const queryClient = useQueryClient();
  
  // State for dialogs
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  // Get mutations
  const { deleteTask } = useTaskMutations();
  
  // Define mutation hook outside of conditional logic
  const clearCompletedTasks = useMutation({
    mutationFn: async () => {
      const taskIds = tasks.map(task => task.id);
      const { error } = await supabase
        .from("to-do")
        .delete()
        .in("id", taskIds);
      
      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["tasks"] });
      toast.success("Completed tasks cleared");
    },
    onError: (error) => {
      toast.error("Failed to clear completed tasks");
      console.error("Error clearing completed tasks:", error);
    },
  });

  // Handlers for TaskList callbacks
  const handleEditTask = (task: Task) => {
    setSelectedTask(task);
    setIsEditOpen(true);
  };

  const handleDeleteTask = (task: Task) => {
    setSelectedTask(task);
    setIsDeleteOpen(true);
  };

  // Handler for delete confirmation
  const handleDeleteConfirm = () => {
    if (selectedTask) {
      deleteTask.mutate(selectedTask.id);
    }
    setIsDeleteOpen(false);
    setSelectedTask(null);
  };

  // Early return after all hooks have been called
  if (tasks.length === 0) return null;

  // Sort tasks by created_at date (most recent first)
  const sortedCompletedTasks = [...tasks]
    .sort((a, b) => {
      const dateA = a.last_completed_at ? new Date(a.last_completed_at).getTime() : (a.created_at ? new Date(a.created_at).getTime() : 0);
      const dateB = b.last_completed_at ? new Date(b.last_completed_at).getTime() : (b.created_at ? new Date(b.created_at).getTime() : 0);
      return dateB - dateA; // Most recent first
    });
  
  // Show just the first 5 if not expanded
  const displayedTasks = expanded ? sortedCompletedTasks : sortedCompletedTasks.slice(0, 5);
  
  const completedToday = sortedCompletedTasks.filter(task => {
    const completionDate = task.last_completed_at ? new Date(task.last_completed_at) : null;
    if (!completionDate) return false;
    const today = new Date();
    return completionDate.toDateString() === today.toDateString();
  }).length;

  return (
    <>
      <Card className="p-6 mt-8 bg-gradient-to-r from-green-50 to-teal-50 dark:from-slate-900 dark:to-slate-800 border border-green-100 dark:border-slate-700 shadow-sm">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center gap-2">
            <h2 className="text-xl font-semibold flex items-center">
              Completed tasks 
              <Badge variant="secondary" className="ml-2 bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-100">
                {tasks.length}
              </Badge>
              {completedToday > 0 && (
                <Badge variant="outline" className="ml-2 border-green-200 text-green-700 dark:border-green-800 dark:text-green-400 flex items-center gap-1">
                  <Calendar className="h-3 w-3" /> 
                  {completedToday} today
                </Badge>
              )}
            </h2>
          </div>
          <div className="flex gap-2">
            <Collapsible open={expanded} onOpenChange={setExpanded} className="w-full">
              <CollapsibleTrigger asChild>
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="h-8 px-2 text-slate-600 hover:text-slate-900 hover:bg-slate-100 dark:text-slate-300 dark:hover:text-white dark:hover:bg-slate-800"
                >
                  {expanded ? <ChevronUp className="h-4 w-4 mr-1" /> : <ChevronDown className="h-4 w-4 mr-1" />}
                  {expanded ? "Show less" : "Show all"}
                </Button>
              </CollapsibleTrigger>
            </Collapsible>
            <Button 
              variant="destructive" 
              size="sm"
              className="h-8 bg-red-100 text-red-700 hover:bg-red-200 hover:text-red-800 dark:bg-red-900 dark:text-red-100 dark:hover:bg-red-800"
              onClick={() => clearCompletedTasks.mutate()}
              disabled={clearCompletedTasks.isPending}
            >
              <Trash2 className="h-4 w-4 mr-1" />
              Clear all
            </Button>
          </div>
        </div>
        
        <Collapsible open={expanded} className="w-full">
          <CollapsibleContent className={expanded ? "space-y-3" : ""}>
            <TaskList 
              tasks={displayedTasks} 
              draggable={false} 
              onEditTask={handleEditTask}
              onDeleteTask={handleDeleteTask}
            />
            
            {sortedCompletedTasks.length > 5 && !expanded && (
              <div className="mt-3 text-center">
                <Button 
                  variant="ghost" 
                  size="sm" 
                  onClick={() => setExpanded(true)}
                  className="text-slate-500 hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-200"
                >
                  <ChevronDown className="h-4 w-4 mr-1" />
                  Show {sortedCompletedTasks.length - 5} more tasks
                </Button>
              </div>
            )}
          </CollapsibleContent>
        </Collapsible>
      </Card>

      {/* Wrap lazy loaded dialogs in Suspense */}
      <Suspense fallback={<div>Loading...</div>}>
        {isEditOpen && selectedTask && (
          <EditTaskDialog
            task={selectedTask}
            open={isEditOpen}
            onOpenChange={(isOpen) => {
              setIsEditOpen(isOpen);
              if (!isOpen) setSelectedTask(null);
            }}
          />
        )}
        {isDeleteOpen && (
          <DeleteTaskDialog
            open={isDeleteOpen}
            onOpenChange={(isOpen) => {
              setIsDeleteOpen(isOpen);
              if (!isOpen) setSelectedTask(null);
            }}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Suspense>
    </>
  );
};

export default CompletedTasks;
