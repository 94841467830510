
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import type { Location } from "react-router-dom";

// Get the production URL, never use localhost
export const getProductionUrl = () => {
  // Hard-coded production URL - regardless of where app is running
  return "https://hvienkhnfdezsbadavpb.lovable.dev";
};

export const handleHashParams = async (location: Location, navigate: (path: string) => void) => {
  // Check if there's an access token in the URL hash
  if (location.hash && location.hash.includes('access_token')) {
    console.log("Detected access token in URL hash, processing confirmation...");
    
    // Extract the type from the hash
    const hashParams = new URLSearchParams(location.hash.substring(1));
    const type = hashParams.get('type');
    
    if (type === 'signup' || type === 'recovery' || type === 'magiclink') {
      console.log("Email confirmation detected, type:", type);
      
      try {
        // Get the session after confirmation
        const { data, error } = await supabase.auth.getSession();
        console.log("Session after confirmation:", { data, error });
        
        if (data.session) {
          // If this is a successful confirmation, check if there's a pending invitation
          // for this user and remove it
          if (data.session.user.email) {
            console.log("Checking for pending invitations for:", data.session.user.email);
            
            // Get the current user's email
            const userEmail = data.session.user.email;
            
            // Find any pending invitation for this email
            const { data: invitations, error: invitationError } = await supabase
              .from('pending_invitations')
              .select('*')
              .eq('email', userEmail);
              
            if (invitationError) {
              console.error("Error checking pending invitations:", invitationError);
            } else if (invitations && invitations.length > 0) {
              console.log("Found pending invitation(s) for confirmed user:", invitations);
              
              // Before removing the invitation, ensure the user profile is updated properly
              // This is crucial to mark them as an active collaborator
              const invitation = invitations[0];
              
              // Check if the user already has a profile
              const { data: existingProfile, error: profileError } = await supabase
                .from('profiles')
                .select('*')
                .eq('id', data.session.user.id)
                .single();
                
              if (profileError && profileError.code !== 'PGRST116') {
                console.error("Error checking existing profile:", profileError);
              }
              
              // If profile exists, update it; otherwise create it
              if (existingProfile) {
                console.log("Updating existing profile for user:", userEmail);
                const { error: updateError } = await supabase
                  .from('profiles')
                  .update({
                    email: userEmail,
                    invited_by: invitation.invited_by,
                    invited_at: invitation.invited_at
                  })
                  .eq('id', data.session.user.id);
                  
                if (updateError) {
                  console.error("Error updating profile:", updateError);
                } else {
                  console.log("Successfully updated profile for user:", userEmail);
                }
              } else {
                console.log("Creating new profile for user:", userEmail);
                const { error: insertError } = await supabase
                  .from('profiles')
                  .insert({
                    id: data.session.user.id,
                    email: userEmail,
                    invited_by: invitation.invited_by,
                    invited_at: invitation.invited_at
                  });
                  
                if (insertError) {
                  console.error("Error creating profile:", insertError);
                } else {
                  console.log("Successfully created profile for user:", userEmail);
                }
              }
              
              // Now remove the pending invitation
              const { error: deleteError } = await supabase
                .from('pending_invitations')
                .delete()
                .eq('email', userEmail);
                
              if (deleteError) {
                console.error("Error removing pending invitation:", deleteError);
              } else {
                console.log("Successfully removed pending invitation for:", userEmail);
              }
            }
          }
          
          toast.success("Email confirmed successfully!");
          
          // CRITICAL FIX: Direct page replacement using window.location.href 
          // to ensure we completely replace the URL with hash fragments
          const productionUrl = getProductionUrl();
          const redirectPath = "/?confirmed=true";
          window.location.href = productionUrl + redirectPath;
          return true;
        }
        
        if (error) {
          console.error("Error processing confirmation:", error);
          toast.error("Failed to confirm email: " + error.message);
        }
      } catch (error) {
        console.error("Error processing auth session:", error);
        toast.error("Failed to process authentication");
      }
    }
  }
  
  return false;
};

export const checkQueryParams = (location: Location) => {
  const queryParams = new URLSearchParams(location.search);
  const invited = queryParams.get("invited");
  const confirmed = queryParams.get("confirmed");
  
  if (invited === "true") {
    toast.info("You were invited to collaborate! Please log in or register to continue.");
  }

  if (confirmed === "true") {
    toast.success("Email confirmed successfully! You now have full access to all features.");
  }
};
