/**
 * Returns the appropriate CSS classes for a task quadrant based on its background color
 */
export function getQuadrantStyles(bgColor: string): string {
  switch (bgColor) {
    case "bg-red-50":
      return "border-priority-urgent-border bg-priority-urgent-bg hover:shadow-md hover:shadow-priority-urgent-border/20";
    case "bg-blue-50":
      return "border-priority-important-border bg-priority-important-bg hover:shadow-md hover:shadow-priority-important-border/20";
    case "bg-yellow-50":
      return "border-priority-delegate-border bg-priority-delegate-bg hover:shadow-md hover:shadow-priority-delegate-border/20";
    case "bg-gray-50":
      return "border-priority-later-border bg-priority-later-bg hover:shadow-md hover:shadow-priority-later-border/20";
    default:
      return "border-gray-200 bg-white";
  }
}
