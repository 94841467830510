import React, { Suspense, ComponentType, ReactNode } from 'react';

interface LazyComponentProps {
  fallback?: ReactNode;
}

/**
 * Creates a lazy-loaded component with a standardized Suspense wrapper
 * @param factory - Factory function that imports the component
 * @param fallback - Optional custom fallback element (defaults to Loading...)
 */
export function createLazyComponent<T extends ComponentType<any>>(
  factory: () => Promise<{ default: T }>,
  defaultFallback: ReactNode = <div>Loading...</div>
) {
  const LazyComponent = React.lazy(factory);
  
  return function LazyLoadedComponent(props: React.ComponentProps<T> & LazyComponentProps) {
    const { fallback = defaultFallback, ...componentProps } = props as any;
    return (
      <Suspense fallback={fallback}>
        <LazyComponent {...componentProps} />
      </Suspense>
    );
  };
}
