import { Badge } from "@/components/ui/badge";
import { format } from "date-fns";
import { Task } from "@/types/task";
import { Clock } from "lucide-react";
import { cn } from "@/lib/utils";
import React from "react";

interface TaskDetailsProps {
  task: Task;
  onTaskClick: () => void;
  checkbox: React.ReactNode;
  isOverdue?: boolean;
}

const TaskDetails = ({ task, onTaskClick, checkbox, isOverdue }: TaskDetailsProps) => {
  const formatDeadlineForDisplay = (deadline: string | null) => {
    if (!deadline) return null;
    try {
      return format(new Date(deadline), "MMM d, h:mm a");
    } catch {
      return "Invalid date";
    }
  };
  const deadlineDisplay = formatDeadlineForDisplay(task.deadline);

  return (
    <div className="flex-1 min-w-0">
      <div className="flex items-start sm:items-center gap-2 sm:gap-3">
        {checkbox}
        <div 
          className={`text-xs sm:text-sm font-medium hover:text-blue-600 cursor-pointer truncate ${
            task.completed ? "text-gray-500 line-through" : "text-gray-900"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onTaskClick();
          }}
        >
          {task.task}
        </div>
        {/* Removed the assignee badge from here since it's now an icon */}
      </div>
      <div className="flex flex-wrap gap-2 mt-1 sm:mt-2 items-center ml-6 sm:ml-7">
        {/* Show red dot if overdue */}
        {isOverdue && (
          <span 
            className="h-2 w-2 bg-red-500 rounded-full inline-block"
            title="Task is overdue"
          />
        )}
        {/* Display formatted deadline */}
        {deadlineDisplay && (
          <span className={cn(
            "text-xs",
            isOverdue ? "text-red-600 font-medium" : "text-gray-500 dark:text-gray-400"
          )}>
            {deadlineDisplay}
          </span>
        )}
        {task.completed && task.created_at && (
          <span className="text-2xs text-green-600 flex items-center gap-1">
            <Clock className="h-3 w-3" />
            Done
          </span>
        )}
        {task.tags?.length > 0 && (
          <div className="flex flex-wrap gap-1">
            {task.tags.map((tag) => (
              <Badge key={tag} variant="secondary" className="text-2xs px-1.5 py-0 h-4">
                {tag}
              </Badge>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default TaskDetails;
