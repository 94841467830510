import { useQueries } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useState } from "react";
import { Task } from "@/types/task";
import EditTaskDialog from "./EditTaskDialog";
import DeleteTaskDialog from "./DeleteTaskDialog";
import TaskItem from "./TaskItem";
import { useTaskMutations } from "@/hooks/useTaskMutations";

interface TaskListProps {
  tasks: Task[];
  isLoading?: boolean;
  draggable?: boolean;
  onEditTask?: (task: Task) => void;
  onDeleteTask?: (task: Task) => void;
}

const TaskList = ({ 
  tasks, 
  isLoading = false, 
  draggable = false, 
  onEditTask, 
  onDeleteTask 
}: TaskListProps) => {
  const { completeTask, deleteTask: deleteTaskMutation } = useTaskMutations();

  const commentQueries = useQueries({
    queries: tasks.map((task) => ({
      queryKey: ["commentCount", task.id],
      queryFn: async () => {
        const { count, error } = await supabase
          .from("comments")
          .select("*", { count: "exact", head: true })
          .eq("task_id", task.id);
        if (error) throw error;
        return count || 0;
      },
    })),
  });

  const handleDeleteClick = (task: Task) => {
    if (onDeleteTask) {
      onDeleteTask(task);
    } else {
      console.warn("onDeleteTask prop not provided to TaskList");
    }
  };

  const handleEditClick = (task: Task) => {
    if (onEditTask) {
      onEditTask(task);
    } else {
      console.warn("onEditTask prop not provided to TaskList");
    }
  };

  const addToGoogleCalendar = (task: Task) => {
    const startTime = task.deadline ? new Date(task.deadline) : new Date();
    const endTime = new Date(startTime.getTime() + 60 * 60 * 1000);

    const event = {
      text: task.task,
      dates: `${startTime.toISOString().replace(/[-:.]/g, "")}Z/${endTime.toISOString().replace(/[-:.]/g, "")}Z`,
    };

    const url = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
      event.text
    )}&dates=${encodeURIComponent(event.dates)}`;

    window.open(url, "_blank");
  };

  if (isLoading) {
    return (
      <div className="space-y-2">
        {[1, 2, 3].map((i) => (
          <div key={i} className="p-3 border rounded-md animate-pulse bg-gray-50 dark:bg-gray-800">
            <div className="h-4 w-2/3 bg-gray-200 dark:bg-gray-700 rounded mb-2"></div>
            <div className="h-3 w-1/3 bg-gray-200 dark:bg-gray-700 rounded"></div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="space-y-2">
      {tasks.map((task, index) => (
        <TaskItem
          key={task.id}
          task={task}
          commentCount={commentQueries[index]?.data}
          onEditClick={handleEditClick}
          onDeleteClick={handleDeleteClick}
          onCalendarClick={addToGoogleCalendar}
          onCompleteClick={completeTask.mutate}
          draggable={draggable}
        />
      ))}
      
      {tasks.length === 0 && (
        <p className="text-sm text-gray-500 text-center py-2">No tasks</p>
      )}
    </div>
  );
};

export default TaskList;
