
import { Progress } from "@/components/ui/progress";
import { Card } from "@/components/ui/card";
import { Badge } from "@/components/ui/badge";
import { CheckCircle } from "lucide-react";

interface TaskProgressProps {
  totalTasks: number;
  completedTasks: number;
}

const TaskProgress = ({ totalTasks, completedTasks }: TaskProgressProps) => {
  const completionPercentage = totalTasks > 0 
    ? Math.round((completedTasks / totalTasks) * 100) 
    : 0;
    
  return (
    <Card className="p-4 mb-6 shadow-sm border border-slate-100">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-sm font-semibold text-slate-800 flex items-center gap-2">
          <CheckCircle className="h-4 w-4 text-green-500" />
          Weekly Progress
        </h2>
        <Badge 
          variant="outline" 
          className="font-semibold bg-green-50 text-green-700 border-green-200"
        >
          {completionPercentage}% Complete
        </Badge>
      </div>
      
      <div className="space-y-2">
        <Progress 
          value={completionPercentage} 
          className="h-2"
          indicatorColor={completionPercentage > 0 ? "bg-green-500" : undefined}
        />
        
        <div className="flex justify-between text-xs text-slate-500">
          <span>{completedTasks} completed</span>
          <span>{totalTasks} total tasks</span>
        </div>
      </div>
    </Card>
  );
};

export default TaskProgress;
