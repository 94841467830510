
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { getProductionUrl } from "@/utils/auth-helpers";

interface AuthFormProps {
  isLogin: boolean;
  setIsLogin: (isLogin: boolean) => void;
  setShowConfirmationInfo: (show: boolean) => void;
  setEmail: (email: string) => void;
  onSuccess?: () => void;
}

const AuthForm = ({ 
  isLogin, 
  setIsLogin, 
  setShowConfirmationInfo, 
  setEmail,
  onSuccess 
}: AuthFormProps) => {
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState("");
  const [password, setPassword] = useState("");

  const handleAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setEmail(emailValue); // Update parent component with email

    try {
      if (isLogin) {
        // Login flow
        console.log("Attempting login with email:", emailValue);
        const { data, error } = await supabase.auth.signInWithPassword({
          email: emailValue,
          password,
        });

        console.log("Login response:", { data, error });

        if (error) {
          if (error.message.includes("Email not confirmed")) {
            toast.error("Please confirm your email before logging in");
            setShowConfirmationInfo(true);
          } else {
            toast.error("Login failed: " + error.message);
          }
        } else {
          const session = data.session;
          // Check if email is verified
          if (session?.user?.email_confirmed_at) {
            toast.success("Logged in successfully");
          } else {
            toast.info("Logged in, but please confirm your email to get full access");
            setShowConfirmationInfo(true);
          }
          onSuccess?.();
        }
      } else {
        // Registration flow
        console.log("Starting registration with email:", emailValue);
        
        // CRITICAL FIX: Always use production URL for redirect
        const redirectUrl = getProductionUrl() + "/auth?confirmed=true";
        
        console.log("Using redirect URL:", redirectUrl);
        
        const { data, error } = await supabase.auth.signUp({
          email: emailValue,
          password,
          options: {
            emailRedirectTo: redirectUrl,
          },
        });

        console.log("Registration response:", { data, error });

        if (error) {
          toast.error("Registration failed: " + error.message);
        } else {
          if (data.user?.identities?.length === 0) {
            toast.error("This email is already registered. Try logging in instead.");
          } else {
            // Successfully registered
            toast.success("Registration successful! Please check your email to confirm your account.");
            setShowConfirmationInfo(true);
            
            // Allow immediate login after registration
            console.log("Attempting immediate login after registration");
            const { data: loginData, error: loginError } = await supabase.auth.signInWithPassword({
              email: emailValue,
              password,
            });
            
            console.log("Immediate login response:", { loginData, loginError });
            
            if (!loginError) {
              onSuccess?.();
            }
          }
        }
      }
    } catch (error) {
      console.error("Authentication error:", error);
      toast.error("Authentication failed");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleAuth} className="space-y-4">
      <Input
        type="email"
        placeholder="Email"
        value={emailValue}
        onChange={(e) => setEmailValue(e.target.value)}
        className="w-full"
        required
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="w-full"
        required
      />
      <Button type="submit" className="w-full" disabled={loading}>
        {loading 
          ? (isLogin ? "Logging in..." : "Registering...") 
          : (isLogin ? "Login" : "Register")}
      </Button>
      <div className="text-center pt-2">
        <button
          type="button"
          onClick={() => setIsLogin(!isLogin)}
          className="text-blue-600 hover:underline text-sm font-medium"
        >
          {isLogin 
            ? "Don't have an account? Register here" 
            : "Already have an account? Login here"}
        </button>
      </div>
    </form>
  );
};

export default AuthForm;
