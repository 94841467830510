import { Card } from "@/components/ui/card";
import TaskList from "./TaskList";
import { Task } from "@/types/task";
import { useState } from "react";
import { useTaskMutations } from "@/hooks/useTaskMutations";
import { createLazyComponent } from "../utils/lazyLoad";

// Lazy load dialogs
const EditTaskDialog = createLazyComponent(() => import("./EditTaskDialog"));
const DeleteTaskDialog = createLazyComponent(() => import("./DeleteTaskDialog"));

interface TasksByDeadlineProps {
  tasks: Task[];
  isLoading: boolean;
}

const TasksByDeadline = ({ tasks, isLoading }: TasksByDeadlineProps) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);

  const { deleteTask } = useTaskMutations();

  const handleEditTask = (task: Task) => {
    setSelectedTask(task);
    setIsEditOpen(true);
  };

  const handleDeleteTask = (task: Task) => {
    setSelectedTask(task);
    setIsDeleteOpen(true);
  };

  const handleDeleteConfirm = () => {
    if (selectedTask) {
      deleteTask.mutate(selectedTask.id);
    }
    setIsDeleteOpen(false);
    setSelectedTask(null);
  };

  return (
    <>
      <Card className="p-3 sm:p-4 mb-6 sm:mb-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-3 sm:mb-4">All tasks by deadline</h2>
        {isLoading ? (
          <div className="animate-pulse h-16 sm:h-20 bg-gray-200 rounded" />
        ) : (
          <TaskList 
            tasks={tasks} 
            draggable={false} 
            onEditTask={handleEditTask}
            onDeleteTask={handleDeleteTask}
          />
        )}
      </Card>

      {/* Lazy loaded dialogs */}
      {isEditOpen && selectedTask && (
        <EditTaskDialog
          task={selectedTask}
          open={isEditOpen}
          onOpenChange={(isOpen) => {
            setIsEditOpen(isOpen);
            if (!isOpen) setSelectedTask(null);
          }}
        />
      )}
      {isDeleteOpen && (
        <DeleteTaskDialog
          open={isDeleteOpen}
          onOpenChange={(isOpen) => {
            setIsDeleteOpen(isOpen);
            if (!isOpen) setSelectedTask(null);
          }}
          onConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

export default TasksByDeadline;
